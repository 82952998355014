<template>
  <div class="table__row">
    <div class="container">
      <div class="table__row-inner">
        <div class="table__cell">
          <div class="table__cell-inner">
            <div v-if="info.northSouth" class="indicator indicator--opacity indicator--orange">N</div>
            <div v-else class="indicator indicator--opacity indicator--blue">S</div>
          </div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.businessName }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.address }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title">{{ info.firstName }} {{ info.lastName }}</div>
        </div>
        <div class="table__cell">
          <div class="table__cell-title table__cell--link" @click.stop>
            <a :href="'tel:' + info.businessPhone">{{ info.businessPhone }}</a>
          </div>
        </div>
      </div>
      <div class="table__row-card card">
        <div class="card__header">
          <div class="card__box">
            <div class="letter">{{ info.businessName[0] }}</div>
          </div>
          <div class="card__box">
            <div class="card__title">{{ info.businessName }}</div>
            <div class="card__subtitle">{{ info.businessPhone }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Customer',
  props: {
    info: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
