<template>
  <div class="page-wrapper">
    <div class="page-inner">
      <div class="page-title-wrapper">
        <div class="container">
          <div class="page-title-inner">
            <div class="users__title page-title">Community Directory</div>
            <div class="field__label--search">
              <i class="ri-search-2-line"></i>
              <input class="field__input" :value="searchParam" placeholder="Search directory" @input="searchClient" />
            </div>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="table__title-wrapper">
          <div class="container">
            <div class="table__title-inner">
              <div class="table__title-item">N/S</div>
              <div class="table__title-item">Business Name</div>
              <div class="table__title-item">Address</div>
              <div class="table__title-item">Contact Name</div>
              <div class="table__title-item">Business Phones</div>
            </div>
          </div>
        </div>
        <div class="table__main">
          <Customer
            v-for="customer in customers"
            :key="customer.id"
            :info="customer"
            @click.native="$router.push('/directory/' + customer.id)"
          />
        </div>
      </div>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import Customer from './Customer'
import InfiniteLoading from 'vue-infinite-loading'
import {mapState, mapActions, mapMutations} from 'vuex'
import _ from 'lodash'

export default {
  name: 'Customers',
  components: {Customer, InfiniteLoading},
  data: () => ({
    isLoading: false
  }),
  computed: {
    ...mapState({
      searchParam: s => s.community.customers.searchParam,
      customers: s => s.community.customers.customers,
      pagination: s => s.community.customers.pagination,
      page: s => s.community.customers.page
    }),

    hasMoreResults() {
      return this.customers.length < this.pagination.total && this.page > 1
    }
  },
  async created() {
    if (!this.customers.length) {
      await this.fetch()
    }
  },
  methods: {
    ...mapActions({
      fetch: 'community/customers/fetch'
    }),
    ...mapMutations({
      changeSearch: 'community/customers/changeSearch',
      reset: 'community/customers/reset'
    }),
    searchClient: _.debounce(async function(e) {
      this.reset()
      this.changeSearch(e.target.value)
      await this.fetch()
    }, 500),
    async infLoad() {
      this.isLoading = true
      try {
        await this.fetch()
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
